import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const EarthIcon = ({ className, onClick }: Props) => {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path
        d="M5 10C5.69167 10 6.34167 9.86875 6.95 9.60625C7.55833 9.34375 8.0875 8.9875 8.5375 8.5375C8.9875 8.0875 9.34375 7.55833 9.60625 6.95C9.86875 6.34167 10 5.69167 10 5C10 4.30833 9.86875 3.65833 9.60625 3.05C9.34375 2.44167 8.9875 1.9125 8.5375 1.4625C8.0875 1.0125 7.55833 0.65625 6.95 0.39375C6.34167 0.13125 5.69167 0 5 0C4.30833 0 3.65833 0.13125 3.05 0.39375C2.44167 0.65625 1.9125 1.0125 1.4625 1.4625C1.0125 1.9125 0.65625 2.44167 0.39375 3.05C0.13125 3.65833 0 4.30833 0 5C0 5.69167 0.13125 6.34167 0.39375 6.95C0.65625 7.55833 1.0125 8.0875 1.4625 8.5375C1.9125 8.9875 2.44167 9.34375 3.05 9.60625C3.65833 9.86875 4.30833 10 5 10ZM5 9C3.88333 9 2.9375 8.6125 2.1625 7.8375C1.3875 7.0625 1 6.11667 1 5C1 4.94167 1.00208 4.88125 1.00625 4.81875C1.01042 4.75625 1.0125 4.70417 1.0125 4.6625C1.05417 4.90417 1.16667 5.10417 1.35 5.2625C1.53333 5.42083 1.75 5.5 2 5.5H3C3.275 5.5 3.51042 5.40208 3.70625 5.20625C3.90208 5.01042 4 4.775 4 4.5V4H6V3C6 2.725 5.90208 2.48958 5.70625 2.29375C5.51042 2.09792 5.275 2 5 2H4.5C4.5 1.80833 4.44792 1.63958 4.34375 1.49375C4.23958 1.34792 4.1125 1.22917 3.9625 1.1375C4.12917 1.09583 4.29792 1.0625 4.46875 1.0375C4.63958 1.0125 4.81667 1 5 1C6.11667 1 7.0625 1.3875 7.8375 2.1625C8.6125 2.9375 9 3.88333 9 5H6.5C5.95 5 5.47917 5.19583 5.0875 5.5875C4.69583 5.97917 4.5 6.45 4.5 7V7.5H6V8.875C5.83333 8.91667 5.66875 8.94792 5.50625 8.96875C5.34375 8.98958 5.175 9 5 9Z"
        fill="#3D3F50"/>
    </svg>
  );
};

export default EarthIcon;
