import React, { ReactNode } from 'react';
import styles from './style.module.scss';

interface Props {
  ratio: string;
  children: ReactNode;
  id?: string;
}

export default function AspectRatio({ ratio, children, id }: Props) {
  const [width, height] = ratio.split(':').map(value => parseInt(value));
  const paddingTop = `${height / width * 100}%`;

  return (
    <div className={styles.component} style={{ paddingTop }} id={id}>
      <div className={styles.wrapper}>{children}</div>
    </div>
  );
}
