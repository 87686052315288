import type { ImageLoaderProps } from 'next/image';
import configJSON from 'config.json';
// @see https://nextjs.org/docs/basic-features/image-optimization#device-sizes for reference
// type ImageSrcWidth = 480 | 640 | 750 | 828 | 1080 | 1200 | 1920 | 2048 | 3840;

type CustomImageLoaderProps = ImageLoaderProps & {
  windowWidth?: number;
  isGalleryImage?: boolean;
  isListingCardImage?: boolean;
};

const ImageWidthResizerSizes = [480, 640, 750, 828, 1080, 1200, 1920, 2048, 3840];

const DefaultImageSrcWidth = 640;
const DefaultImageQuality = 75;
const DefaultCdnPrefix = 'cdn0';
const SmallestImageSrcWidth = 480;

/** Loader responsible for preventing `NextImage` from loading images with width larger than the specified `DefaultImageSrcWidth` and forcing a single CDN entry point */ 
const imageLoader = ({ src, quality, windowWidth, isGalleryImage, isListingCardImage }: CustomImageLoaderProps) => {
  let imageSrcWidth = DefaultImageSrcWidth;
  if (isListingCardImage || (windowWidth && windowWidth < 640)) {
    imageSrcWidth = SmallestImageSrcWidth;
  }
  if (windowWidth && windowWidth > 640 && isGalleryImage) {
    // Find closest resizer width depending on window size
    const galleryImageSize = windowWidth / 2;
    imageSrcWidth = ImageWidthResizerSizes.reduce((prev, curr) => Math.abs(curr - galleryImageSize) < Math.abs(prev - galleryImageSize) ? curr : prev);
  }

  if (src.includes(configJSON.expImageBaseUrl)) {
    return `${src}?w=${imageSrcWidth}`;
  }

  const cdnUrl = src.replace(/(cdn[0-9])/gi, DefaultCdnPrefix);
  return `/_next/image?url=${encodeURIComponent(cdnUrl)}&w=${imageSrcWidth}&q=${quality || DefaultImageQuality}`;
};

export default imageLoader;
