import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import { capitalize } from '@zoocasa/node-kit/strings/capitalize';

import type Listing from 'data/listing';
import type { SimilarListingCardData } from 'components/dynamic-page/listing-page/similar-listings-sections/similar_listing_card_data';

export default function getListingImageAlt(listing: Listing | SimilarListingCardData) {
  if ('homeType' in listing) {
    const homeType = listing.homeType ? capitalize(deDasherize(listing.homeType)) : 'Home';
    return `${listing?.seoStreet}, ${homeType} with ${listing.bedrooms} bedrooms, ${listing.bathrooms} bathrooms and ${listing.parking} parking in ${listing.city} ${listing.province}`;
  } else {
    return `${listing.bedrooms} bedrooms, and ${listing.bathrooms} bathrooms in ${listing.city} ${listing.province}`;
  }
}
