import SquareWrapper, { SquareIconProps } from '../wrapper';
import ArrowIcon from 'components/icon/arrow-icon';

type Props = SquareIconProps & {className?: string};

export default function SquareArrowIcon ({ onClick, className, size, testId, isHoverable, bgColor }: Props) {
  return (
    <SquareWrapper onClick={onClick} size={size} testId={testId} isHoverable={isHoverable} bgColor={bgColor}>
      <ArrowIcon className={className}/>
    </SquareWrapper>
  );
}