import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const FoldIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" className={className} onClick={onClick}>
      <path d="M5.14286 6.85714V12H3.42857V8.57143H0V6.85714H5.14286ZM8.57143 0V3.42857H12V5.14286H6.85714V0H8.57143Z" fill="black"/>
    </svg>
  );
};

export default FoldIcon;
