import React from 'react';
import styles from './style.module.scss';
import Button, { TERTIARY_THEME } from 'components/control/button';
import { buildClassName } from 'utils/build-class-name';
import Link from 'components/link';
import InfoIconColour from 'components/icon/info-icon-colour';

export enum BannerContentType {
  CREA = 'crea',
}

interface Props {
  className?: string;
  content: BannerContentType;
  onDismiss: () => void;
}

function Banner({ className, content, onDismiss }: Props) {
  let bannerContent;
  if (content) {
    switch (content) {
    case BannerContentType.CREA:
      bannerContent = (
        <p>
          By using our site, you agree to our <Link href="/terms-of-use" target="_blank" onClick={onDismiss}>Terms of Use</Link>
        </p>
      );
      break;
    default:
      break;
    }
  }

  return (bannerContent ?
    <div className={buildClassName(styles.component, className)}>
      <div className={styles.content}>
        <InfoIconColour className={styles.icon} />
        <div>{bannerContent}</div>
      </div>
      <div>
        <Button
          onClick={onDismiss}
          label={'Dismiss'}
          theme={TERTIARY_THEME}
          className={styles['custom-button-style']}
        />
      </div>
    </div>
    :
    null
  );
}

export default Banner;