import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const ArrowIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#191B1E" fillRule="nonzero" transform="translate(-1299 -458)">
          <g transform="rotate(90 435 885)">
            <path d="M24.118 18.434l-6.565-6.307a1.35 1.35 0 00-1.852 0l-6.565 6.307a1.223 1.223 0 000 1.641c.471.529 1.301.59 1.852.138L16.62 14.8l5.646 5.412c.513.49 1.34.49 1.852 0 .51-.492.51-1.287 0-1.779z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowIcon;
