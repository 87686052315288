import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const ExpandIcon2 = ({ className, onClick }: Props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path d="M0 12L0 6.66667H1.33333V9.73333L9.73333 1.33333H6.66667V0L12 0V5.33333H10.6667V2.26667L2.26667 10.6667H5.33333V12H0Z" fill="black"/>
      </g>
    </svg>
  );
};

export default ExpandIcon2;
