/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect } from 'react';
import { ModalContext, IModalContext } from 'contexts/modal';
import TopBar from 'components/modal-dialog/photo-gallery/top-bar';
import Map from 'components/swiper-carousel/map';
import Image from 'components/listing-image';
import AspectRatio from 'components/aspect-ratio';
import ImageGalleryCta from 'components/image-gallery-cta';
import styles from './style.module.scss';
import getListingImageAlt from 'utils/image-alt-description';
import getImageSrc from 'utils/get-image';
import useWindowWidth from 'hooks/use-window-width';
import ImageDescription from 'components/image-description';
import Banner, { BannerContentType } from 'components/banner';
import { useUser } from 'contexts';

import type Listing from 'data/listing';
import type { GeoJsonPoint } from '@zoocasa/node-kit/map/types';

export enum PhotoGalleryTestIds {
  IMAGE_DESCRIPTION = 'imageDescription',
}

export interface PhotoGalleryOptions {
  listing: Listing;
  photoIndex?: number | string;
  isModal?: boolean;
}

interface Props {
  closeModal: () => void;
  options: PhotoGalleryOptions;
}

export default function PhotoGallery({ options: { listing, photoIndex, isModal = false }}: Props) {
  const { areZoocasaTermsRead, setZoocasaTermsStateToRead } = useUser();
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { imageUrls, imageDescs } = listing;
  const imageAlt = getListingImageAlt(listing);
  const windowWidth = useWindowWidth();

  const openLightbox = (activeImageIndex: number) => {
    openModal('lightbox', { listing, activeImageIndex });
  };

  useEffect(() => {
    if (photoIndex) {
      document.getElementById(`photo-${photoIndex}`)?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [photoIndex]);

  return (
    <div className={styles.component}>
      {listing.isCrea && !areZoocasaTermsRead &&
        <Banner content={BannerContentType.CREA} onDismiss={() => setZoocasaTermsStateToRead()} className={styles['banner-custom-style']} />
      }
      <TopBar listing={listing} />
      <div className={styles.grid} id="grid">
        {imageUrls.map((imageUrl, index) => {
          const alt = imageDescs && imageDescs.has(index.toString()) ?
            `${imageDescs.get(index.toString())} | Image ${index + 1}` : `${imageAlt} | Image ${index + 1}`;
          const imageDesc = (imageDescs && imageDescs.has(index.toString())) ?
            imageDescs.get(index.toString()) : '';
          return (
            <AspectRatio key={index} ratio="4:3" id={`photo-${index}`}>
              <Image
                item={{ image: getImageSrc(imageUrl), alt, id: `${listing.id}-card-image`, addedAt: listing.addedAt }}
                isVisible={true}
                className={styles.image}
                onClick={() => openLightbox(index)}
                isGalleryImage={true}
                width={windowWidth}
              />
              {imageDesc && <ImageDescription description={imageDesc} />}
            </AspectRatio>
          );
        })}
        <AspectRatio ratio="4:3" id="photo-map">
          {listing?.position && <Map listingId={`${listing!.id}-gallery`} fullScreenOption={false} position={(listing.position as unknown as GeoJsonPoint)} className={styles.map} />}
        </AspectRatio>
        <AspectRatio ratio="4:3">
          <ImageGalleryCta listing={listing} isModal={isModal} />
        </AspectRatio>
      </div>
    </div>
  );
}
