import React from 'react';
import styles from './style.module.scss';
import { PhotoGalleryTestIds } from '../modal-dialog/photo-gallery';
import { testIds } from 'constants/test-constants';

interface Props {
  description: string;
}

const ImageDescription = ({ description }: Props) => {
  return (
    <div className={styles.component} data-testid={testIds.imageDescription} >
      <p className={styles.description} data-testid={PhotoGalleryTestIds.IMAGE_DESCRIPTION}>{description}</p>
    </div>
  );
};

export default ImageDescription;