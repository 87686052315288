import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './style.module.scss';
import LoadWhenVisible from 'components/load-when-visible';
import { mapPageIds } from 'constants/test-constants';
import AppleMap from '@zoocasa/node-kit/map/apple/map';
import SatelliteControl from 'components/search-es/map/SatelliteControl';
import { drawInitialMap } from 'utils/apple-maps';
import { trackEvent } from 'utils/google-tag-manager';
import {
  GTM_CLICK_ADDRESS_MODAL_MAP_SATELLITE_VIEW,
  GTM_CLICK_ADDRESS_MODAL_MAP_STREET_VIEW,
  GTM_CLICK_ADDRESS_PAGE_MAP_SATELLITE_VIEW,
  GTM_CLICK_ADDRESS_PAGE_MAP_STREET_VIEW,
} from 'constants/events';
import { useThemeContext } from 'contexts';
import MapZoomControls from './MapZoomControls';
import Button from 'components/control/button';
import ExpandIcon from 'components/icon/expand-icon-2';
import FoldIcon from 'components/icon/fold-icon';
import { ThemeNames } from 'types/themes';

export interface GeoJSONPoint {
  type: 'Point';
  coordinates: [number, number];
}

interface Props {
  className?: string;
  listingId: number | string;
  trackEventLocation?: TrackEventLocation;
  position: GeoJSONPoint;
  address?: string;
  fullScreenOption?: boolean;
  isInteractive?: boolean;
  loadWhenVisible?: boolean;
}

export enum TrackEventLocation {
  Modal = 'Modal',
  Page = 'Page',
}

const Map = ({
  className,
  position,
  listingId,
  address,
  trackEventLocation = TrackEventLocation.Page,
  isInteractive = true,
  loadWhenVisible = true,
  fullScreenOption = true,
}: Props) => {
  const mapElementId = `listing-map-${listingId}`;
  const fullMapElementId = `listing-full-map-${listingId}`;
  const [mapShouldLoad, setMapShouldLoad] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const mapRef = useRef<AppleMap | null>(null);
  const fullMapRef = useRef<AppleMap | null>(null);
  const { themeName } = useThemeContext();

  const handleSatelliteAndStreetViewToggled = useCallback(() => {
    const _mapRef = isFullScreen ? fullMapRef : mapRef;
    if (_mapRef?.current !== null) {
      if (_mapRef.current?.map.mapType === mapkit.Map.MapTypes.Standard) {
        _mapRef.current.map.mapType = mapkit.Map.MapTypes.Satellite;
        trackSatelliteViewEventByLocation();
        return mapkit.Map.MapTypes.Satellite;
      } else if (_mapRef.current?.map.mapType === mapkit.Map.MapTypes.Satellite) {
        _mapRef.current.map.mapType = mapkit.Map.MapTypes.Standard;
        trackStreetViewEventByLocation();
        return mapkit.Map.MapTypes.Standard;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen]);

  const trackSatelliteViewEventByLocation = () => {
    if (trackEventLocation === TrackEventLocation.Page) {
      trackEvent(GTM_CLICK_ADDRESS_PAGE_MAP_SATELLITE_VIEW);
    } else if (trackEventLocation === TrackEventLocation.Modal) {
      trackEvent(GTM_CLICK_ADDRESS_MODAL_MAP_SATELLITE_VIEW);
    }
  };

  const trackStreetViewEventByLocation = () => {
    if (trackEventLocation === TrackEventLocation.Page) {
      trackEvent(GTM_CLICK_ADDRESS_PAGE_MAP_STREET_VIEW);
    } else if (trackEventLocation === TrackEventLocation.Modal) {
      trackEvent(GTM_CLICK_ADDRESS_MODAL_MAP_STREET_VIEW);
    }
  };

  useEffect(() => {
    if (mapShouldLoad && mapRef.current === null) {
      drawInitialMap(mapElementId, position, isInteractive, themeName as ThemeNames)
        .then(mapInstance => {
          mapRef.current = mapInstance;
        })
        .catch((error: any) => {
          console.error('Error loading map:', error);
        });
    }
  }, [mapShouldLoad, mapElementId, position, isInteractive, themeName]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === 'Escape') {
        handleCloseMap();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen]);

  useEffect(() => {
    if (!loadWhenVisible) {
      setMapShouldLoad(true);
    }
  }, [loadWhenVisible]);

  // Function to open the full-screen map
  const handleOpenFullScreen = () => {
    if (TrackEventLocation.Page) {
      trackEvent('UiAddressButMapClk');
      trackEvent('UiAddressMapModFullScreenView');
      trackEvent('UiAddressMapButFullMapClk');
    } else {
      trackEvent('UiListingMButMapClk');
      trackEvent('UiListingMMapModFullScreenView');
      trackEvent('UiListingMMapButFullMapClk');
    }
    setIsFullScreen(true);
    drawInitialMap(fullMapElementId, position, isInteractive, themeName as ThemeNames)
      .then(mapInstance => {
        fullMapRef.current = mapInstance;
        fullMapRef.current.map.mapType = mapRef.current?.map.mapType;
      })
      .catch((error: any) => {
        console.error('Error loading map:', error);
      });
  };

  const handleCloseMap = () => {
    setIsFullScreen(false);
    if (mapRef && fullMapRef) mapRef.current.map.mapType = fullMapRef.current?.map.mapType;
  };

  const zoomIn = () => {
    if (isFullScreen && fullMapRef.current) {
      fullMapRef.current.zoomIn();
    } else if (mapRef.current) {
      mapRef.current.zoomIn();
    }
  };

  const zoomOut = () => {
    if (isFullScreen && fullMapRef.current) {
      fullMapRef.current.zoomOut();
    } else if (mapRef.current) {
      mapRef.current.zoomOut();
    }
  };

  return (
    <>
      {isFullScreen && (
        <div className={styles['full-screen-overlay']}>
          <div className={styles['full-screen-map-container']}>
            <div className={styles['title-container']}>
              <h1>{address}</h1>
              <button className={styles['close-button']} onClick={() => {
                const eventName = trackEventLocation === TrackEventLocation.Page ? 'UiAddressMapButXClk' : 'UiListingMMapButXClk';
                trackEvent(eventName);
                handleCloseMap();
              }}>
                &times;
              </button>
            </div>
            <SatelliteControl
              className={styles['satellite-toggle-button']}
              onViewToggle={handleSatelliteAndStreetViewToggled}
            />
            <MapZoomControls zoomIn={zoomIn} zoomOut={zoomOut} tenantName={themeName} />
            <Button label={'Close Map'} theme={'secondary'} Icon={FoldIcon} className={styles['full-map-button']} onClick={() => {
              const eventName = trackEventLocation === TrackEventLocation.Page ? 'UiAddressMapButCloseMapClk' : 'UiListingMMapButCloseMapClk';
              trackEvent(eventName);
              handleCloseMap();
            }} />
            <div
              id={fullMapElementId}
              className={styles['full-screen-map']}
            />
          </div>
        </div>
      )}

      {loadWhenVisible ? (
        <LoadWhenVisible onValueChange={() => setMapShouldLoad(true)}>
          <div className={styles.component}>
            <SatelliteControl className={styles['custom-position']} onViewToggle={handleSatelliteAndStreetViewToggled} />
            {fullScreenOption &&
            <>
              <MapZoomControls zoomIn={zoomIn} zoomOut={zoomOut} tenantName={themeName} />
              <Button label={'Full Map'} theme={'secondary'} Icon={ExpandIcon} className={styles['full-map-button']} onClick={handleOpenFullScreen} />
            </>}
            <div id={mapElementId} className={className} data-testid={mapPageIds.listingMap} />
          </div>
        </LoadWhenVisible>
      ) : (
        <div className={styles.component}>
          <SatelliteControl className={styles['custom-position']} onViewToggle={handleSatelliteAndStreetViewToggled} />
          {fullScreenOption &&
            <>
              <MapZoomControls zoomIn={zoomIn} zoomOut={zoomOut} tenantName={themeName} />
              <Button label={'Full Map'} theme={'secondary'} Icon={ExpandIcon} className={styles['full-map-button']} onClick={handleOpenFullScreen} />
            </>}
          <div id={mapElementId} className={className} data-testid={mapPageIds.listingMap} />
        </div>
      )}
    </>
  );
};

export default Map;
