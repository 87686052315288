import React from 'react';
import { buildClassName } from 'utils/build-class-name';
import MinusIcon from 'components/icon/minus-icon';
import PlusIcon from 'components/icon/plus-icon';
import { ThemeNames } from 'types/themes';
import styles from './style.module.scss';
import { ThemeName } from 'themes';

interface Props {
  tenantName: ThemeName;
  zoomIn: () => void;
  zoomOut: () => void;
}

const MapZoomControls = ({ zoomIn, zoomOut, tenantName }: Props) => {
  const isZooTenant = tenantName === ThemeNames.ZOOCASA;

  return (
    <div className={buildClassName(styles['zoom-control-group'], isZooTenant && styles['is-zoo'])}>
      <button className={styles['button']} onClick={zoomOut}><MinusIcon /></button>
      <button className={styles['button']} onClick={zoomIn}><PlusIcon /></button>
    </div>
  );
};

export default MapZoomControls;